@media (max-width: 500px) {
  .shopping-cart-bottom-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .shopping-cart-bottom-section > div {
    min-width: 250px;
  }
}